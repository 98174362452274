export const getUserRole = () => {
    if (!localStorage.getItem('frotaToken')) {
        return null;
    }
    const userJwt = localStorage.getItem('frotaToken') || ""
    const user = JSON.parse(atob(userJwt.split('.')[1]))

    return user.role;
}

export const capitalize = (string: string) => {
    return string[0].toUpperCase() + string.substring(1);
}

export const getCurrentMonthDatesOnly = (): string[] => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth(); // 0-indexed (0 = January, 11 = December)

    // Get the last day of the month
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    // Create an array of date strings in the format YYYY-MM-DD
    const datesArray: string[] = [];

    for (let day = 1; day <= daysInMonth; day++) {
        datesArray.push(day.toString());
    }

    return datesArray;
};


export const clearSession = () => {
    localStorage.removeItem('frotaToken');
    localStorage.removeItem('refreshToken');
}

