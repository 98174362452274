// DailyReport.tsx
import React, { useEffect, useState } from 'react';
import './DailyReport.css';
import MembershipReport from '../../components/Reports/MembershipReport';
import MerchandiseReport from '../../components/Reports/MechandiseReport';
import { createHttpService } from '../../services/httpService';
import { FROTA_BACKEND_URL } from '../../config';
import { Membership, Merchandise, PrivateClass } from '../../config/types';
import StyledButton from '../../components/StyledComponents/StyledButton';
import { toast } from 'react-toastify';
import OverlayLoader from '../../components/Loader/OverlayLoader';
import { getUserRole } from '../../services/baseServices';
import PrivateClassReport from '../../components/Reports/PrivateClassReport';

interface getDailyReportResponse {
    memberships: Membership[];
    privateClasses: PrivateClass[];
    merchandises: Merchandise[];
}

const DailyReport: React.FC = () => {

    const [reportDate, setReportDate] = useState('');
    const [isReportsLoading, setIsReportsLoading] = useState(true);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const [memberships, setMemberships] = useState<Membership[]>([]);
    const [privateClasses, setPrivateClasses] = useState<PrivateClass[]>([]);
    const [merchandises, setMerchandises] = useState<Merchandise[]>([]);

    const userRole = getUserRole();

    const totalSales =
        (memberships?.length > 0 ? memberships.reduce((acc, cur) => acc + cur.price, 0) : 0) +
        (privateClasses?.length > 0 ? privateClasses.reduce((acc, cur) => acc + cur.price, 0) : 0) +
        (merchandises?.length > 0 ? merchandises.reduce((acc, cur) => acc + cur.price, 0) : 0);


    const getDailyReportforDate = async () => {
        const httpService = createHttpService(FROTA_BACKEND_URL);
        try {
            // Construct the query parameters
            const params = new URLSearchParams({
                date: reportDate,
            });
            setIsReportsLoading(true);
            const result = await httpService.get<getDailyReportResponse>(`/dailyReports?${params.toString()}`,);

            if (!result.success || !result.data) {
                throw new Error(result.message ?? 'getDailyReportforDate(): Unknown error');
            }

            setMemberships(result?.data?.memberships);
            setPrivateClasses(result?.data?.privateClasses);
            setMerchandises(result?.data?.merchandises);

            return result;
        } catch (error) {
            throw new Error(
                `getDailyReportforDate(): ${error instanceof Error ? error.message : 'Unknown error'}`,
            );
        } finally {
            setIsReportsLoading(false);
        }
    }


    useEffect(() => {
        const currentDate = new Date().toISOString();
        console.log(currentDate);
        if (currentDate?.includes('T')) {
            setReportDate(currentDate.split('T')[0]);
        }
    }, [])

    useEffect(() => {
        if (!reportDate) {
            return;
        }
        getDailyReportforDate();
    }, [reportDate])

    const handleSaveReport = async () => {

        const httpService = createHttpService(FROTA_BACKEND_URL);

        try {
            setIsSaveLoading(true);
            const formattedMemberships = memberships.map((merchandise) => {
                return {
                    martialArt: merchandise.martialArt,
                    price: merchandise.price,
                    duration: merchandise.duration,
                    paymentType: merchandise.paymentType,
                    studentName: merchandise.studentName
                }
            })
            const formattedPrivateClasses = privateClasses.map((perClass) => {
                return {
                    studentName: perClass.studentName,
                    price: perClass.price,
                    quantity: perClass.quantity
                }
            })
            const formattedMerchandises = merchandises.map((merchandise) => {
                return {
                    item: merchandise.item,
                    pricePerItem: merchandise.pricePerItem,
                    price: merchandise.price,
                    quantity: merchandise.quantity
                }
            })

            const result = await httpService.post(`/dailyReports/save`, {
                memberships: formattedMemberships,
                privateClasses: formattedPrivateClasses,
                merchandises: formattedMerchandises,
                date: reportDate
            });

            console.log(result)
            if (!result.success || !result.data) {
                throw new Error(result.message ?? 'handleSaveReport(): Unknown error');
            }

            toast.success('Daily Report has been Saved.')

        } catch (error) {
            throw new Error(
                `handleSaveReport(): ${error instanceof Error ? error.message : 'Unknown error'}`,
            );
        } finally {
            setIsSaveLoading(false);
        }
    }


    return (
        <div className="report-container">
            <h2>DAILY REPORT FOR:
                <input
                    type="date"
                    className="date-input"
                    value={reportDate}
                    disabled={userRole !== 'admin'}
                    onChange={(e) => { setReportDate(e.target.value); console.log(e.target.value) }} /></h2>

            {/* Membership Report Component */}
            <MembershipReport
                memberships={memberships}
                setMemberships={setMemberships} />

            {/* Private Classes Report Component */}
            <PrivateClassReport
                privateClasses={privateClasses}
                setPrivateClasses={setPrivateClasses} />

            {/* Merchandise Report Component */}
            <MerchandiseReport
                merchandises={merchandises}
                setMerchandises={setMerchandises} />

            {/* <div style={{ background: '#dedede', borderRadius: 6, padding:10}}> */}
            <table>
                <tr>
                    <td colSpan={1} className="total-label">TOTAL SALES</td>
                    <td colSpan={1} className="total-value">
                        ${totalSales}
                    </td>
                </tr>
            </table>
            {/* </div> */}

            <OverlayLoader isLoading={isSaveLoading || isReportsLoading} />

            <div className='mt-4 d-flex justify-content-center'>
                <StyledButton onClick={handleSaveReport}>Save Report</StyledButton>
            </div>
        </div>
    );
};

export default DailyReport;
